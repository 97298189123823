html, body, #root {
  height: 100%;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}
